<template>
  <div class="epidemicPrevent">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :isManySelect="true"
      :tableUrl="tableUrl"
      :searchParam.sync="searchParam"
      :exportParams="exportParams"
      @selection-change="selectionChange"
    >
      <template #headBtnSlot>
        <v-button text="重置" @click="toReset"></v-button>
        <v-button text="返回" @click="toReturn"></v-button>
      </template>
      <template #searchSlot>
        <v-input label="昵称" v-model="searchParam.nickName" />
        <v-input label="手机号" v-model="searchParam.phone" />
        <v-select
          clearable
          :options="sexStatus"
          v-model="searchParam.sex"
          @change="$refs.list.search()"
          label="性别"
        />
        <v-date-picker
          label="创建时间"
          v-model="createTime"
          type="datetimerange"
          formatValue="yyyy-MM-dd HH:mm:ss"
          startPlaceholder="创建开始时间"
          endPlaceholder="创建结束时间"
        />
      </template>
      <template #operateSlot="scope">
        <v-button text="查看" type="text" @click="toEdit(scope.row)" />
        <v-button text="删除" type="text" @click="toDelete(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { epidemicPostUrl, epidemicOutUrl, deleteEpidemicUrl } from "./api.js";
import {
  formType,
  formTypeMap,
  formPostType,
  formPostTypeMap,
  sexStatus,
  sexStatusMap,
} from "./map.js";
import { createAlinkVNode, createImgVNode } from "@/utils/utils.js";

export default {
  name: "epidemicPrevent",
  data() {
    return {
      sexStatus,
      formPostType,
      formType,
      exportParams: {
        url: epidemicOutUrl,
        methods: "post",
        params: {
          recordIds: [],
        },
      },
      searchParam: {
        formsId: "",
        nickName: "",
        phone: "",
        sex: "",
        createTimeS: "",
        createTimeE: "",
      },

      tableUrl: epidemicPostUrl,
      headers: [
        {
          prop: "nickName",
          label: "昵称",
        },
        {
          prop: "phone",
          label: "手机号",
        },
        {
          prop: "sex",
          label: "性别",
          formatter: (row, prop) => {
            return sexStatusMap[row[prop]] || "--";
          },
        },
        {
          prop: "createTime",
          label: "提交时间",
        },
      ],
    };
  },
  created() {
    this.searchParam.formsId = this.$route.query.formsId;
  },
  computed: {
    createTime: {
      get() {
        return !this.searchParam.createTimeS && !this.searchParam.createTimeE
          ? []
          : [this.searchParam.createTimeS, this.searchParam.createTimeE];
      },
      set(val) {
        [this.searchParam.createTimeS, this.searchParam.createTimeE] = val || [
          "",
          "",
        ];
      },
    },
  },
  mounted() {
    this.$setBreadList("防疫表单提交记录");
  },
  methods: {
    selectionChange(val) {
      console.log(val, 998);
      let arr = [];
      for (var i = 0; i < val.length; i++) {
        arr.push(val[i].recordId);
      }
      this.exportParams.params.recordIds = arr;
    },
    toReturn() {
      this.$router.go(-1);
    },
    toReset() {
      this.searchParam = {
        nickName: "",
        phone: "",
        sex: "",
        createTimeS: "",
        createTimeE: "",
      };
    },
    toOut() {},
    toDelete(item) {
      this.$confirm("是否确定删除?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        let params = {
          recordId: item.recordId,
        };
        this.$axios
          .post(`${deleteEpidemicUrl}`, this.$qs.stringify(params))
          .then((res) => {
            if (res.code === 200) {
              this.$message.success("操作成功");
              this.$refs.list.search();
            } else {
            }
          });
      });
    },
    toEdit(row) {
      this.$router.push({
        name: "epidemicPostDetail",
        query: { id: row.recordId },
      });
    },
  },
};
</script>
<style lang="less" scoped>
.epidemicPrevent {
  box-sizing: border-box;
  height: 100%;
}
</style>
